@import "../../styles/setting.module";

.Input {
  box-sizing: border-box;
  width: 100%;
}

.Label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.InputElement {
  outline: none;
  border: 1px solid $ci-darkgrey;
  background-color: $ci-white-bg;
  margin: 10px 0;
  font: inherit;
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 3rem;

  &__checkbox {
    margin-right: $small-rem;
    height: $very-large-rem;
    width: $very-large-rem;
  }
}

.InputElementTextArea {
  height: auto;
}

.InputElement:focus {
  outline: none;
  background-color: #ccc;
}

.Invalid {
  border: 1px solid $ci-error-red;
  background-color: $ci-error-salmon;
}
