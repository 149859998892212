@import "../../styles/setting.module";
@import "../../styles/tools.module";

.Logo {
  //@include border(1px, 0, $ci-grey);
  max-height: 60px;
  padding: 10px;
  @include font-weight(bold);
  color: $ci-lightblue-bg;
  img {
    height: 45px;
  }
}
