@import "./setting.module";
@import "./tools.module";

.Listing {
  padding: 10px;
  // @include border(5px, 0, $ci-lightblue-bg);
  border-bottom: 0px;
  min-height: 200px;

  &__title {
    margin-top: 0;
  }

  &__selling-message {
    @include font-size("medium");
    color: $ci-red;
    margin: $small-rem 0;
  }

  &__link {
    text-decoration: none;
    display: block;

    &:link {
      color: $ci-very-dark-grey;
    }

    &:hover {
      color: $ci-very-dark-grey;
    }

    &:active {
      color: $ci-very-dark-grey;
    }

    &:visited {
      color: $ci-very-dark-grey;
    }
  }

  &__main-content {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
  }

  &__thumb {
    @include border(2px, 0, $ci-teal);
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  &__button {
    margin: 0 !important;

    a {
      color: $ci-white-bg;
    }
  }
}

.Listing-attribute {
  display: flex;
  @include font-size("medium");
  margin: $small-rem $no-rem;

  &__label {
    @include font-weight("bold");
    margin-right: $even-rem;
  }

  &__value {
  }
}
