@import "../../../styles/setting.module";
@import "../../../styles/tools.module";

.ListingsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  >div:nth-child(even) {
    background: $ci-white-bg;
  }

  >div:nth-child(odd) {
    background: $ci-white-bg;
  }

  // > div:last-child {
  //   border-bottom: 5px solid $ci-lightblue-bg;
  // }
}