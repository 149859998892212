@import "../../styles/setting.module";

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $ci-teal;
  color: white;
  text-align: center;
  padding: 2px;

  p {
    margin: 0;
  }

  a {
    color: white;
  }
}
