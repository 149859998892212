@import "./setting.module";

html {
  // font-size: calc(#{$base-font-size} + 1vw);
  font-size: $base-font-size;
  line-height: $base-line-height;
}

body {
  // font: calc(#{$base-font-size} + 1vw) "Segoe UI", Helvetica, Arial;
  font: $base-font-size "Segoe UI", Helvetica, Arial;
  background-color: $ci-purple;
  color: $ci-darkblue;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

form {
  // width: 80%;
  // margin: 4rem auto;
  font-size: 1.5rem;
}

fieldset {
  font-size: 2.25rem;
  line-height: 1.5;
}

label,
input,
button,
textarea,
select {
  font-size: 1.125rem;
}

button {
  cursor: pointer;
}

ul {
  padding: 0px;
  margin: 0px;
}

* {
  box-sizing: border-box;
}

a {
  &:link {
    color: $ci-very-dark-grey;
  }

  &:hover {
    color: $ci-very-dark-grey;
  }

  &:active {
    color: $ci-very-dark-grey;
  }

  &:visited {
    color: $ci-very-dark-grey;
  }
}

// table {
//   width: 100%;
//   @include font-size(12px);

//   @include media-query(lap-and-up) {
//     @include font-size(16px);
//   }
// }

// th {
//   @include font-weight(bold);
//   text-align: left;
// }

// th,
// td {
//   padding: $spacing-unit--tiny;
// }
