@import "../../../styles/setting.module";
@import "../../../styles/tools.module";

.Container {
  width: 100%;
  // margin: 1rem;

  @media only screen and (max-width: $laptop-and-above-breakpoint) {
  }

  > H1,
  H2,
  H3 {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }
}
