@import "../../styles/setting.module";

.Auth {
  // border: 10px solid $ci-lightblue-bg;
  // padding: 1em;
}

.Authform_links {
  font-size: 1rem;
}

.Aboutus {
  margin-top: $large-rem;
  padding-top: $even-rem;
  border-top: 2px solid $ci-very-dark-grey;
}

.WhatTheSiteDoes {
  margin: $even-rem;
}
