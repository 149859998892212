$base-font-size: 14px;
$base-line-height: 14px;

// https://coolors.co/0a2342-b9d6f2-4f7cac-662020-cacfd6
$ci-white-bg: #fff;
$ci-darkblue: #0a2342;
$ci-lightblue: #b9d6f2;
$ci-midblue: #4f7cac;
$ci-red: #662020;
$ci-grey: #cacfd6;
$ci-darkgrey: #aaaaaa;
$ci-lightblue-bg: #e9f6ff;
$ci-very-dark-grey: #2d2d38;
$ci-error-red: red;
$ci-error-salmon: salmon;
$ci-purple: #e7d0fe;
$ci-teal: #00b3b3;

$laptop-and-above-breakpoint: 600px;
$content-max-width: 800px;
$screen-max-width: 1200px;

$no-rem: 0rem;
$small-rem: 0.4rem;
$even-rem: 1rem;
$medium-rem: 1.25rem;
$large-rem: 1.5rem;
$very-large-rem: 2rem;
$very-very-large-rem: 4rem;
