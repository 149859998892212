@import "../../../styles/setting.module";

.Listing {
  &__image-section {
    margin-top: $small-rem;
  }
  &__images {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: $no-rem;
  }

  &__image {
    margin: $small-rem;
    object-fit: contain;
  }
}
