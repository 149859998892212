@import "../../../../styles/setting.module";
@import "../../../../styles/tools.module";

.NavigationItem {
  margin: 0;
  height: 100%;
  width: 80%;
  @include border(2px, 0, $ci-grey);
  padding: 4px;
  @include font-weight(bold);
  color: $ci-lightblue-bg;
  background-color: $ci-very-dark-grey;
  text-align: center;
  align-self: stretch;
}

.NavigationItem__Link {
  color: white;
  text-decoration: none;
  height: 100%;

  &:link {
    color: $ci-white-bg;
  }
}

.active {
  color: $ci-midblue;

  &:hover {
    color: $ci-midblue;
  }
  &:active {
    color: $ci-midblue;
  }
  &:visited {
    color: $ci-midblue;
  }
}

@media only screen and (min-width: $laptop-and-above-breakpoint) {
  .NavigationItem {
    width: 100%;
  }
}
