@import "./setting.module";

@mixin border($thickness, $radius, $color) {
  border-radius: $radius;
  border: $thickness solid $color;
}

@mixin margin($top-size, $right-size, $bottom-size, $left-size) {
  margin: $top-size $right-size $bottom-size $left-size;
}

@mixin sky-gradient() {
  background: #2c3e50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c3e50,
    #3498db
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c3e50,
    #3498db
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@mixin font-weight($font-weight) {
  @if ($font-weight == "light") {
    font-weight: 300;
  }

  @if ($font-weight == "regular") {
    font-weight: 400;
  }

  @if ($font-weight == "bold") {
    font-weight: 600;
  }
}

@mixin font-size($font-size) {
  @if ($font-size == "small") {
    font-size: $small-rem;
  }

  @if ($font-size == "medium") {
    font-size: $medium-rem;
  }

  @if ($font-size == "large") {
    font-size: $large-rem;
  }

  @if ($font-size == "very large") {
    font-size: $very-large-rem;
  }
}
