@import "../../../styles/setting.module";

.Menu {
  width: 100%;
  // max-width: $screen-max-width;
  padding: 0px;
  position: fixed;
  top: 58px;
  height: 40px;
  border-top: 2px solid $ci-grey;
  z-index: 90;

  a {
    color: $ci-white-bg;
  }
}