:global {
  @import "./_setting.module";

  @import "./_tools.module";

  @import "./_generic.module";

  @import "./_elements.module";

  @import "./_objects.module";

  @import "./_trumps.module";
}
