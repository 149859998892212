@import "../../../styles/setting.module";
@import "../../../styles/tools.module";

.Listing-details {
  // padding: 0.75rem;
  // @include border(5px, 0, $ci-lightblue-bg);

  &__title {
    @include font-size("large");
    @include margin($small-rem, $no-rem, $small-rem, $no-rem);
  }

  &__selling-message {
    @include font-size("medium");
    color: $ci-red;
    margin: $small-rem 0;

    animation: myAnimation 1s;
    animation-direction: reverse;

    @keyframes myAnimation {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  display: flex;
  flex-direction: column;

  &__swiper {
    // max-width: $laptop-and-above-breakpoint;
    // border: 1px solid purple;
    // z-index: -10 !important;
    border: 3px solid $ci-teal;

    padding: 0.5rem;
  }

  &__swiper-slide {
    height: 35vh;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (min-width: $laptop-and-above-breakpoint) {
    &__image {
      object-fit: contain;
    }

    // &__swiper-slide {
    //   height: 40vh;
    // }
  }

  &__description {
    margin: $no-rem;
    padding: $no-rem;
  }

  &__meanings {
    margin: $no-rem;
    padding: $no-rem;
  }

  &__forSale {
    @include font-size("medium");
    color: $ci-red;
    font-weight: bold;
    background-color: $ci-white-bg;
    padding: 0.5rem;
    position: absolute;
  }
}

.seller-info-container {
  background-color: $ci-white-bg;
  @include margin($even-rem, $no-rem, $even-rem, $no-rem);

  &__title {
    background-color: $ci-very-dark-grey;
    padding: 10px;
    color: $ci-white-bg;
    @include font-size("large");
    @include font-weight("bold");
  }
}

.seller-contact-details {
  padding: 10px;
  @include border(2px, 0, $ci-very-dark-grey);
  @include font-size("medium");
  @include font-weight("bold");

  &__email-button {
    width: 100%;
    background-color: $ci-teal;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
  }

  &__email-button-link {
    text-decoration: none;
    color: $ci-white-bg !important;
  }

  &__telephone {
  }
}
