@import "../../styles/setting.module";

.Error {
  list-style: none;
  margin-top: 1rem;

  &__invalidInput {
    font-size: 1rem;
    color: $ci-error-red;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}
