@import "../../styles/setting.module";
@import "../../styles/tools.module";

.btn {
  /* @include border(2px, 0, $ci-grey);*/
  display: block;
  max-height: 60px;
  border: none;
  padding: 0.75rem;
  margin: 1rem 0rem;
  @include font-weight(bold);
  color: $ci-lightblue-bg;
  background-color: $ci-teal;

  & .active {
    color: red !important;
  }

  a {
    text-decoration: none;
  }
}

.btn_disabled {
  border: 1px solid red;
}
