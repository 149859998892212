@import "../../styles/setting.module";

.ValidationSummary {
  list-style: none;
  margin-top: 1rem;

  &__invalidInput {
    font-size: 1rem;
    color: $ci-error-red;
  }
}
