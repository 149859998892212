@import "../../styles/setting.module";

.FreeTextSearch {
  display: flex;
  align-items: center;

  width: 100%;

  input {
    display: inline;
  }

  button {
    padding: 4px;
    height: 3rem;

    a {
      color: $ci-white-bg;
    }
  }
}